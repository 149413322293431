
import { IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { defineComponent } from 'vue';
import BannerArea from "@/components/BannerArea.vue";
import NewsletterArea from "@/components/NewsletterArea.vue";

export default defineComponent({
  components: {
    NewsletterArea,
    BannerArea,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
  }
});


import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import {
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  calendarOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
  informationCircle,
  informationCircleOutline,
  listCircle,
  listCircleOutline,
  logoFacebook,
  logoGithub,
  logoTwitter,
  // logoGoogle,
  logoYoutube,
  cartOutline
} from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Trang chủ',
        name: 'Home',
        url: '/',
        iosIcon: mailOutline,
        mdIcon: mailSharp
      },
      {
        title: 'Giới thiệu',
        name: 'About',
        url: '/about',
        iosIcon: informationCircleOutline,
        mdIcon: informationCircle
      },
      {
        title: 'Dịch vụ',
        name: 'Services',
        url: '/services',
        iosIcon: heartOutline,
        mdIcon: heartSharp
      },
      {
        title: 'Dự án',
        name: 'Portfolio',
        url: '/portfolio',
        iosIcon: listCircleOutline,
        mdIcon: listCircle
      },
      // {
      //   title: 'Blog',
      //   name: 'Blog',
      //   url: '/blog',
      //   iosIcon: calendarOutline,
      //   mdIcon: calendarOutline
      // },
      {
        title: 'Liên hệ',
        name: 'Contact',
        url: '/contact',
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp
      }
    ];
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

    /* const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    } */

    const route = useRoute();
    return {
      selectedIndex,
      appPages,
      labels,
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      logoFacebook,
      logoGithub,
      logoTwitter,
      // logoGoogle,
      logoYoutube,
      isSelected: (name: string) => name === route.name ? 'selected' : ''
    }
  }
});

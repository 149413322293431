import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import AboutPage from "@/views/AboutPage.vue";
import ContactPage from "@/views/ContactPage.vue";
import BlogPage from "@/views/BlogPage.vue";
import ElementsPage from "@/views/ElementsPage.vue";
import PortfolioPage from "@/views/PortfolioPage.vue";
import PortfolioDetailsPage from "@/views/PortfolioDetailsPage.vue";
import ServicesPage from "@/views/ServicesPage.vue";
import SingleBlogPage from "@/views/SingleBlogPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: HomePage
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogPage
  },
  {
    path: '/elements',
    name: 'Elements',
    component: ElementsPage
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: PortfolioPage
  },
  {
    path: '/portfolio/:id',
    name: 'PortfolioDetails',
    component: PortfolioDetailsPage
  },
  {
    path: '/services',
    name: 'Services',
    component: ServicesPage
  },
  {
    path: '/blog/:id',
    name: 'SingleBlog',
    component: SingleBlogPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

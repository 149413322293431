
import { IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { defineComponent } from 'vue';
import BannerArea from "@/components/BannerArea.vue";
import AboutUsArea from "@/components/AboutUsArea.vue";
import BrandArea from "@/components/BrandArea.vue";
import TestimonialArea from "@/components/TestimonialArea.vue";
import NewsletterArea from "@/components/NewsletterArea.vue";
import FooterArea from "@/components/FooterArea.vue";
import { useMeta } from 'vue-meta';

export default defineComponent({
  setup() {
    useMeta({
      title: 'Giới thiệu',
      description: {
        content: 'description'
      }
    });
  },
  components: {
    FooterArea,
    NewsletterArea,
    TestimonialArea,
    BrandArea,
    AboutUsArea,
    BannerArea,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
  }
});

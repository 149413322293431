
import { IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { defineComponent } from 'vue';
import FeaturesArea from "@/components/FeaturesArea.vue";
import PortfolioArea from "@/components/PortfolioArea.vue";
import TestimonialArea from "@/components/TestimonialArea.vue";
import NewsletterArea from "@/components/NewsletterArea.vue";
import FooterArea from "@/components/FooterArea.vue";
import { useMeta } from 'vue-meta';
export default defineComponent({
  setup() {
    useMeta({
      title: 'Trang chủ',
      description: {
        content: 'dicol'
      }
    });
  },
  components: {
    FooterArea,
    // NewsletterArea,
    // TestimonialArea,
    // PortfolioArea,
    // FeaturesArea,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonMenuButton,
  }
});
